import adapters.AudioContext
import components.CellPanel
import components.InfoPanel
import components.Intro
import components.Noise
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.MainScope
import react.child
import react.dom.render

val mainScope: CoroutineScope = MainScope()
val audioContext = try { AudioContext() } catch(e: Throwable) { null }


fun main() {
    render(document.getElementById("root")) {
        child(Intro)
        child(InfoPanel)
        child(CellPanel)
        child(Noise)
    }
}