import org.w3c.dom.CanvasRenderingContext2D
import kotlin.math.PI
import kotlin.math.cos

fun CanvasRenderingContext2D.upTriangle(x: Double, y: Double, sideLength: Double, state: Boolean, color: String = "#ffaabb") = this.apply {
    val height = (sideLength * cos(PI / 6))
    beginPath()
    moveTo(0.0 + x, height + y)
    lineTo(sideLength + x, height + y)
    lineTo(sideLength/2 + x, 0.0 + y)
    closePath()
    if (state) {
        fillStyle = color
        fill()
    }
}

fun CanvasRenderingContext2D.downTriangle(x: Double, y: Double, sideLength: Double, state: Boolean, color: String = "#ffaabb") = this.apply {
    val height = (sideLength * cos(PI / 6))
    beginPath()
    moveTo(0.0 + x, 0.0 + y)
    lineTo(sideLength + x, 0.0 + y)
    lineTo(sideLength/2 + x, height + y)
    closePath()
    if (state) {
        fillStyle = color
        fill()
    }
}

fun CanvasRenderingContext2D.clear() =
    this.clearRect(0.0, 0.0, this.canvas.width.toDouble(), this.canvas.height.toDouble())