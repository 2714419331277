package components

import react.RProps
import react.dom.a
import react.dom.attrs
import react.dom.li
import react.functionalComponent
import styled.css
import styled.styledUl

val Menu = functionalComponent<RProps> { props ->
    val items = listOf(
        "Bio",
        "Projects",
        "Resume",
        "Contact"
    )
    styledUl {
        css { +Style.menu }
        items.forEach {
            li {
                a {
                    +it
                    attrs {
                        href = "#$it"
                    }
                }
            }
        }
    }
}