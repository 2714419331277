package components

import adapters.*
import adapters.Color
import audioContext
import components.NoiseGen.getSceneHeight
import components.NoiseGen.getSceneWidth
import kotlinext.js.asJsObject
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.js.*
import mainScope
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.MouseEvent
import react.*
import react.dom.*
import styled.*
import kotlin.js.json
import kotlin.math.PI
import kotlin.math.abs
import kotlin.math.tan
import kotlin.random.Random


object NoiseGen : ThreeGen() {
    val job: Job = SupervisorJob()
    val scope: CoroutineScope = mainScope + job

    val noise = audioContext!!.createBuffer(1, audioContext.sampleRate * 2, audioContext.sampleRate)
        .let {
            val output = it.getChannelData(0)
            for (i in 0..audioContext.sampleRate.toInt() * 2)
                output[i] = Random.nextFloat() * 2 - 1
            audioContext.createBufferSource().apply {
                buffer = it
                loop = true
                start()
            }
        }

    val bp = audioContext!!.createBiquadFilter().apply {
        this.type = "bandpass"
        frequency.value = 220.0
        this.Q.value = 120.0
    }
    val gain = audioContext!!.createGain().apply {
        gain.value = 3.0
    }

    fun init() {
        noise.connect(bp).connect(gain).connect(audioContext!!.destination)
        audioContext!!.suspend()
    }

    var isVisible = false

    val isoGeo = IcosahedronGeometry(10.0)
    val material = MeshPhongMaterial(
        json("color" to 0xD28F2F,
            "flatShading" to true,
            "opacity" to 0.3,
            "transparent" to true,
            "side" to 2
        ).asJsObject()
    )

    val camera = PerspectiveCamera(75.0, 1.0, 15.0, 35.0).apply {
        position.z = 25.0
    }

    val objects = List(3) {
        Mesh(
            isoGeo,
            MeshPhongMaterial(
                json("color" to Random.nextInt(),
                    "flatShading" to true,
                    "opacity" to 0.3,
                    "transparent" to true,
                    "side" to 2
                ).asJsObject()
            )
        ).also {
            val width = getSceneWidth(25.0).toInt()
            val height = getSceneHeight(25.0).toInt()
            val xdist = Random.nextInt(-(width / 2), width / 2).toFloat()
            val ydist = Random.nextInt(-(height / 2), height / 2).toFloat()
            it.translateY(ydist)
            it.translateY(xdist)
            scene.add(it)
        }
    }

    fun getSceneHeight(depth: Double = 0.0) : Double {
        var acc = depth
        val cposZ = camera.position.z
        if (depth < cposZ) acc -= cposZ
        else acc += cposZ

        var rFov = camera.fov * PI / 180
        return 2 * tan(rFov / 2) * abs(acc)
    }
    fun getSceneWidth(depth: Double = 0.0) : Double {
        val height = getSceneHeight(depth)
        return height * camera.aspect
    }

    override fun setSize() {
        with(renderer.domElement) {
            if (width != clientWidth || height != clientHeight) {
                renderer.setSize(clientWidth.toDouble(), clientHeight.toDouble())
                camera.aspect = (parent.clientWidth / parent.clientHeight).toDouble()
                camera.updateProjectionMatrix()
            }
        }
    }

    override fun bind(element: HTMLElement) {
        parent = element
        element.appendChild(renderer.domElement)
        render()
        renderer.domElement.style.apply {
            element.let {
                width = "${it.clientWidth}px"
                height = "${it.clientHeight}px"
            }
        }
        setSize()
    }


    override fun render() {
        window.requestAnimationFrame { render() }

        objects.forEachIndexed { i, it ->
            when(i) {
                0 -> it.rotateX(0.001f)
                1 ->  it.rotateY(0.001f)
                2 -> it.rotateZ(0.001f)
            }
        }
        renderer.render(scene, camera)
    }
}


val Noise = functionalComponent<RProps> { props ->
    val (playing, setPlaying) = useState(false)
    val (freq, setFreq) = useState(220)
    val (audioState, setAudioState) = useState("Tap for sound")

    fun doSound(e: Event) {
        (e.asDynamic().nativeEvent as MouseEvent).let {
            println("${ it.clientX }, ${ it.clientY }")
            if (audioContext != null) {
                NoiseGen.scope.launch {
                    NoiseGen.bp.frequency.value = it.clientY.toLong()
                    audioContext.resume()
                    delay(it.clientX.toLong())
                    audioContext.suspend()
                }
            }
        }
    }

    fun addLight (e: Event) {
        (e.asDynamic().nativeEvent as MouseEvent).let {
            val light = PointLight(Color(0xffffff), 1.0, 0.0, 1.0)
            NoiseGen.scene.add(light)
            val width = getSceneWidth(25.0).toFloat()
            val height = getSceneHeight(25.0).toFloat()
            light.translateX(width - width / 2)
            light.translateY(height - height / 2)
            PointLightHelper(light, 5.0, 0xffffff).apply { update(); NoiseGen.scene.add(this) }
        }
    }
    useEffectOnce {
        if (audioContext == null) {
            setAudioState("Sorry, it seems your browser doesn't support the web audio api")
        } else {
            NoiseGen.init()
        }
    }
    styledDiv {
        css {
            +Style.panel
            +Style.noisepanel
        }
        attrs {
            onClickFunction = {
                doSound(it)
                addLight(it)
            }
            ref(NoiseGen::bind)
        }
        styledH1 {
            css {
                gridColumn = GridColumn("2")
                gridRow = GridRow("1")
            }
            +"Filtered Noise"
        }
        styledH2 {
            css {
                gridColumn = GridColumn("2")
                gridRow = GridRow("2")
            }
            +audioState
        }

        styledDiv {
            h2 {
                +"Volume"
            }
            styledInput {
                attrs {
                    type = InputType.range
                    min = "0"
                    max = "8"
                    step = "0.5"
                    defaultValue = "3"
                    onInputFunction = {
                        NoiseGen.gain.gain.value = (it.target as HTMLInputElement).value
                    }
                }
            }
        }


//
//        h2 {
//            +freq.toString()
//        }
    }

}