import kotlin.random.Random


enum class CellularAutomata (val transformation: (List<Boolean>)->List<Boolean>, val origin: (Int)->List<Boolean>){
    Rule30(
        fun (last: List<Boolean>) : List<Boolean> {
            return last.mapIndexed { index, b ->
                when(index) {
                    0 -> if (b) !b else last[1]
                    (last.size - 1) -> {
                        when(last[last.size - 1]) {
                            true -> !last[last.size - 2]
                            false -> last[last.size - 2]
                        }
                    }
                    else -> {
                        val a = last[index - 1]
                        val c = last[index + 1]
                        when {
                            (a && b) -> false
                            (a && !b) -> !c
                            (!a && b) -> true
                            else -> c
                        }
                    }
                }
            }
        },
        { size: Int -> List(size) { it == (size / 2) } }
    ),
    Rule22(
        fun (last: List<Boolean>) : List<Boolean> {
            return last.mapIndexed { index, b ->
                when(index) {
                    0 -> if (b) !b else !last[1]
                    (last.size - 1) -> {
                        when(last[last.size - 1]) {
                            true -> !last[last.size - 2]
                            false -> last[last.size - 2]
                        }
                    }
                    else -> {
                        val a = last[index - 1]
                        val c = last[index + 1]
                        when {
                            (a && b) -> false
                            (a && !b) || (!a && b) -> !c
                            else -> c
                        }
                    }
                }
            }
        },
        { size: Int -> List(size) { false } }
    ),
    Rule106(
        fun (last: List<Boolean>) : List<Boolean> {
            return last.mapIndexed { index, b ->
                when(index) {
                    0 -> if (b) !last[index + 1] else last[index + 1]
                    (last.size - 1) -> Random.nextBoolean()
                    else -> {
                        val a = last[index - 1]
                        val c = last[index + 1]
                        when {
                            (a && b) -> !c
                            else -> c
                        }
                    }
                }
            }
        },
        { size: Int -> List(size) { false } }
    )
}