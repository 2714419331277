package components

import CellularAutomata
import clear
import components.AutomataGen.adjustSize
import downTriangle
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.*
import mainScope
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLElement
import react.createElement
import upTriangle
import kotlin.math.PI
import kotlin.math.cos
import kotlin.properties.Delegates.observable

object AutomataGen {
    val job: Job = SupervisorJob()
    val scope: CoroutineScope = mainScope + job
    var gridWidth: Int = 0
    var gridHeight: Int = 0
    var cellSize = 10.0
    var refresh : Long = 250
    var rule: CellularAutomata = CellularAutomata.Rule30
    var gen: Sequence<List<Boolean>> = generateSequence { listOf() }
    var color: String = "#71AFD2"

    var elem : HTMLCanvasElement by observable(document.createElement("canvas") as HTMLCanvasElement) { _, old, new ->
        window.addEventListener("resize", { new.adjustSize() })
        window.addEventListener("load", { new.adjustSize() })
    }

    val displayGrid: MutableList<List<Boolean>> = mutableListOf()

    var contextRef: CanvasRenderingContext2D? = null

    fun bind(element: HTMLCanvasElement) {
        (element.getContext("2d") as CanvasRenderingContext2D).let {
            contextRef = it
            element.adjustSize()
            gen =  generateSequence(rule.origin(gridWidth), rule.transformation)
            displayGrid.initGrid(gridWidth, gridHeight, gen.drop(gridHeight * 2).iterator())
            println("$gridWidth, $gridHeight")
            elem = element
            start(it)
        }

    }

    private fun HTMLCanvasElement.adjustSize() {
        if (clientHeight != height || clientWidth != width) {
            gridWidth = ((clientWidth / cellSize) * 2).toInt()
            gridHeight = ((clientHeight / (cellSize * cos(PI / 6)))).toInt()
            width = clientWidth
            height = clientHeight
        }
    }

    infix fun MutableList<List<Boolean>>.addNext(row: List<Boolean>) {
        removeFirst()
        add(row)
    }

    fun reloadGen() {
        stop()
        displayGrid.clear()
        elem.adjustSize()
        gen =  generateSequence(rule.origin(gridWidth), rule.transformation)
        displayGrid.initGrid(gridWidth, gridHeight, gen.drop(gridHeight * 2).iterator())
        start(elem.getContext("2d") as CanvasRenderingContext2D)
    }

    fun MutableList<List<Boolean>>.initGrid(width: Int, height: Int, source: Iterator<List<Boolean>>? = null) {
        addAll(List(height) { source?.next() ?: List(width) { false } })
    }

    fun CanvasRenderingContext2D.render(grid: MutableList<List<Boolean>>, cellSize: Double) {
        this.apply {
            grid.forEachIndexed { i, row ->
//            Build Rows
                val y = i * (cellSize * cos(PI / 6))
                val offset = ((i % 2) * (cellSize/2)) - (cellSize/2)
                row.forEachIndexed { j, state ->
//            Build Columns per row
                    val x = j * (cellSize / 2)
                    if (j % 2 == 1) {
                        upTriangle(x + offset, y, cellSize, state, color)
                    } else {
                        downTriangle(x + offset, y, cellSize, state, color)
                    }
                }
            }
        }
    }

    fun stop() {
        job.cancelChildren()
    }

    fun start(context: CanvasRenderingContext2D) {
        gen.drop(gridHeight * 2).let { next ->
            scope.launch {
                for (row in next) {
                    if (isActive) {
                        displayGrid addNext row
                        with(context) {
                            clear()
                            render(displayGrid, cellSize)
                        }
                        delay(refresh)
                    } else break
                }
            }
        }
    }
}