package components

import adapters.Scene
import adapters.WebGLRenderer
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLElement
import kotlin.js.json
import kotlin.properties.Delegates

abstract class ThreeGen {
    open val renderer = WebGLRenderer(
        json("antialias" to true, "alpha" to true)
    )
    open var parent: HTMLElement by Delegates.observable(document.createElement("canvas") as HTMLCanvasElement) { _, _, new ->
        new.appendChild(renderer.domElement)
        window.addEventListener("resize", { setSize() })
        window.addEventListener("load", { setSize() })
    }
    open val scene = Scene()
    abstract fun setSize()
    abstract fun bind(element: HTMLElement)
    abstract fun render()
}