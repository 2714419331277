package components

import adapters.*
import adapters.Color
import kotlinx.browser.window
import kotlinx.css.*
import org.w3c.dom.HTMLElement
import react.*
import styled.css
import styled.styledDiv
import kotlin.math.PI

object SpiroGen : ThreeGen() {
    val camera = PerspectiveCamera(75.0, 1.0, 15.0, 35.0).apply {
        position.z = 25.0
    }
    val torusGeo = TorusKnotGeometry(8.0, 1.0, 100, 16)
    val torus = torusGeo.apply {
        Mesh(this,
            MeshBasicMaterial().apply {
                color = Color(0xffaabb)
                wireframe = true
            }
        ).also {
            scene.add(it)
            it.rotateX(PI.toFloat()/2)
        }
    }

    override fun bind(element: HTMLElement) {
        parent = element
        element.appendChild(renderer.domElement)
        render()
        setSize()
    }

    override fun setSize() {
//        adjust canvas resolution if needed
        with(renderer.domElement) {
            if (width != clientWidth || height != clientHeight) {
                renderer.setSize(clientWidth.toDouble(), clientHeight.toDouble())
                camera.aspect = (parent.clientWidth / parent.clientHeight).toDouble()
                camera.updateProjectionMatrix()
            }
        }
    }

    override fun render() {
        window.requestAnimationFrame { render() }
        torus.apply {
            rotateZ(0.001)
            rotateY(window.scrollY*0.0001)
        }
        renderer.render(scene, camera)
    }
}

val Spiro = functionalComponent<RProps>() {
    styledDiv {
        css {
            display = Display.block
            position = Position.absolute
            width = 90.vw
            height = 50.vh
            margin(5.vh, 8.vw, 0.px)
            zIndex = -1
            media("screen and (max-width: 450px)") {
                margin(12.vh, 8.vw, 0.px)
                height = 40.vh
            }
            children {
                width = 100.pct
                height = 100.pct
            }
        }
        ref(SpiroGen::bind)
    }
}
