package components

import Style
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.js.onChangeFunction
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import react.RProps
import react.dom.*
import react.functionalComponent
import react.setState
import react.useState
import styled.css
import styled.styledCanvas
import styled.styledDiv
import styled.styledSelect


val CellPanel = functionalComponent<RProps>() {
    val (color, setColor) = useState("#71AFD2")
    styledDiv {
        css {
            +Style.panel
            +Style.cellpanel
        }
        styledDiv {
            css {
                gridColumn = GridColumn("1 / 3")
                gridRow= GridRow("2 / 3")
                padding (0.rem, 2.rem)
            }
            h1 {
                +"Elementary Cellular Automata"
            }
        }
        styledDiv {
            css {
                gridColumn = GridColumn("1 / 3")
                gridRow= GridRow("3")
                padding(0.px, 4.rem)
            }
            p {
                +"""
                    A cellular automaton is a discrete model of computation in which 
                    each point in a grid evolves according to its neighbors. In this case, 
                    new rows are inserted on the bottom based on the row above.
                """.trimIndent()
            }
            br {}
            p {
                +"""
                    Here I've implemented three of my favorite rules. 
                    There are 256 in total, just as there are 256 possible 
                    combinations of 8 bits. 
                """.trimIndent()
            }
        }
        styledCanvas {
            css {
                gridColumn = GridColumn("2 / 4")
                gridRow = GridRow("1 / 3")
                width = 100.pct
                height = 100.pct
                zIndex = -1
                maxWidth = 600.px
            }
            ref(AutomataGen::bind)
        }
        styledDiv {
            css {
                gridColumn = GridColumn("3")
                gridRow = GridRow("4")
            }
            styledSelect {
                css {
                    fontSize = 4.rem
                }
                CellularAutomata.values().forEach {
                    option {
                        +"Rule ${ it.name.drop(4) }"
                        attrs {
                            value = it.name
                        }
                    }
                }
                attrs {
                    onChangeFunction = {
                        val aut = it.target as HTMLSelectElement
                        AutomataGen.rule = CellularAutomata.valueOf(aut.value)
                        AutomataGen.reloadGen()
                    }
                }
            }
        }

    }
}