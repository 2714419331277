package components

import Style
import kotlinx.css.*
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import react.RProps
import react.child
import react.dom.*
import react.functionalComponent
import react.useEffectOnce
import styled.css
import styled.styledCanvas
import styled.styledDiv
import styled.styledUl

val Intro = functionalComponent<RProps> {
    styledDiv {
        css {
            +Style.panel
            +Style.intro
        }
        h1 {
            +"Alexander Porter"
        }
        h2 {
            +"Designer | Developer | Multimedia Artist"
        }
        child(Spiro)
        attrs {
            id = "intro"
        }
    }
}