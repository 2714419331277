package components

import Style.textLight
import Style.typography
import kotlinx.css.*
import kotlinx.css.properties.TextDecoration
import kotlinx.css.properties.TextDecorationLine
import kotlinx.css.properties.TextDecorationStyle
import kotlinx.html.js.onClickFunction
import kotlinx.html.style
import react.RProps
import react.dom.a
import react.dom.attrs
import react.dom.h2
import react.functionalComponent
import styled.css
import styled.styledDiv

val InfoPanel = functionalComponent<RProps> {
    styledDiv {
        css {
            padding(5.rem, 5.rem)
            fontFamily = "open-sans"
            fontSize = 3.rem
            color = Color.white
            backgroundColor = Style.Colors.text
        }
        styledDiv {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                children {
                    color = Color.white
                    textDecoration = TextDecoration.none
                    hover {
                        textDecoration = TextDecoration(setOf(TextDecorationLine.underline))
                    }
                    visited {
                        textDecoration = TextDecoration.none
                        color = Color.white
                    }
                }
            }
            a {
                +"Contact"
            }
            a {
                +"Resume"
                attrs {
                    href = "./Resume_Sept10_2021e.pdf"
                }
            }
            a {
                +"Github"
                attrs {
                    href = "https://github.com/xcporter"
                }
            }
        }

    }
}