import kotlinx.css.*
import kotlinx.css.properties.TextDecoration
import kotlinx.css.properties.border
import styled.StyleSheet

object Style : StyleSheet("Style", true) {

    object Colors {
        val text = Color("#363638")
    }

    val panel by css {
        height = 100.vh
        width = 100.vw
    }

    val textDark by css {
        h1 {
            color = Colors.text
        }
        h2 {
            color = Colors.text
        }
        p {
            color = Colors.text
        }
    }

    val textLight by css {
        h1 {
            color = Color.white
        }
        h2 {
            color = Color.white
        }
        p {
            color = Color.white
        }
    }

    val typography by css {
        h1 {
            fontFamily = "montserrat"
            fontSize = 8.rem
        }
        h2 {
            fontFamily = "open-sans"
            fontSize = 3.rem
        }
        p {
            fontFamily = "open-sans"
            fontSize = 1.5.rem
        }
    }
    val intro by css {
        display = Display.grid
        gridTemplateColumns = GridTemplateColumns(1.fr, 4.fr, 4.fr, 1.fr)
        gridTemplateRows = GridTemplateRows(2.fr, 0.5.fr, 0.5.fr)

        media("screen and (max-width: 450px)") {
            gridTemplateRows = GridTemplateRows(1.fr, 1.fr, 0.5.fr, 0.5.fr)
        }

        + typography
        + textDark

        h1 {
            gridRow = GridRow("-3")
            gridColumn = GridColumn("2 / 4")
        }

        h2 {
            margin(0.px, 20.px)
            gridRow = GridRow("-2")
            gridColumn = GridColumn("2 / 4")
        }
    }

    val cellpanel by css {
        display = Display.grid
        gridTemplateColumns = GridTemplateColumns(0.8.fr, 0.5.fr, 1.fr)
        gridTemplateRows = GridTemplateRows(1.fr, 1.fr, 0.2.fr, 1.fr)
        +typography
        +textDark
    }

    val noisepanel by css {
        gridTemplateColumns = GridTemplateColumns(1.2.fr, 1.fr)
        gridTemplateRows = GridTemplateRows(1.fr, 2.fr, 1.fr, 0.5.fr)
        gap = 2.rem
        display = Display.grid
        backgroundColor = Colors.text
        +typography
        +textLight
    }

    val sliderThumb by css {
        put("-webkit-appearance", "none")
        border(2.px, BorderStyle.solid, Color.white)
        height = 1.rem
        width = 0.5.rem
        cursor = Cursor.pointer
    }

    val menu by css {
        listStyleType = ListStyleType.none
        fontFamily = "open-sans"
        fontSize = 1.5.rem
        a {
            textDecoration = TextDecoration.none
            color = Colors.text
        }
    }
}